// src/components/AuthForm.js
import React, { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

const AuthForm = () => {
  const {
    signIn,
    signUp,
    teams,
    setShowAuthForm,
    authErrorMessage,
    setAuthErrorMessage,
  } = useAppContext();
  const [authMode, setAuthMode] = useState("signin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [favoriteTeamId, setFavoriteTeamId] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    console.log("Teams in AuthForm:", teams);
  }, [teams]);

  useEffect(() => {
    // Set the error message from authErrorMessage if it exists
    if (authErrorMessage) {
      setError(authErrorMessage);
      setAuthErrorMessage(""); // Clear the global error message
    }
  }, [authErrorMessage, setAuthErrorMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (authMode === "signin") {
        await signIn(email, password);
      } else {
        if (!favoriteTeamId) {
          setError("Please select a favorite team");
          return;
        }
        const favoriteTeamIdNumber = parseInt(favoriteTeamId, 10);
        if (isNaN(favoriteTeamIdNumber)) {
          setError("Invalid team selection");
          return;
        }
        await signUp(email, password, username, favoriteTeamIdNumber);
      }
      setShowAuthForm(false);
    } catch (err) {
      console.error("Auth error:", err);
      setError(err.message);
    }
  };

  const handleClose = () => {
    setShowAuthForm(false);
    setAuthErrorMessage(""); // Clear the global error message when closing
  };

  return (
    <div className="auth-overlay" onClick={handleClose}>
      <div className="auth-form-container" onClick={(e) => e.stopPropagation()}>
        <div className="auth-form-header">
          <h2
            className={authMode === "signin" ? "active" : ""}
            onClick={() => setAuthMode("signin")}
          >
            Sign In
          </h2>
          <h2
            className={authMode === "signup" ? "active" : ""}
            onClick={() => setAuthMode("signup")}
          >
            Register
          </h2>
        </div>
        {error && <p className="auth-error">{error}</p>}
        <form className="auth-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {authMode === "signup" && (
            <>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <select
                value={favoriteTeamId}
                onChange={(e) => setFavoriteTeamId(e.target.value)}
                required
              >
                <option value="">Select Favorite Team</option>
                {teams.map((team) => (
                  <option key={team.id} value={team.id.toString()}>
                    {team.team_name}
                  </option>
                ))}
              </select>
            </>
          )}
          <button type="submit">
            {authMode === "signin" ? "Sign In" : "Sign Up"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;
